import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import headerStyle from "../layouts/header.module.css";

export default ({ imgSrcPath }) => {
  return (
    <StaticQuery
      query={graphql`
        query LoadSiteConfigHeader {
          contentfulSiteConfiguration {
            headerTheme
            fonts
            colorHex
            backgroundImage {
              file {
                url
              }
            }
            footer {
              json
            }
            backgroundChoice
          }
          contentfulSectionWhoWeAre {
            showTeamMemberList
          }
        }
      `}
      render={(data) => {
        const config = data.contentfulSiteConfiguration;
        const defaultSiteNavigation =
          data.contentfulSectionWhoWeAre.showTeamMemberList;
        const [showSidePanel, toggleSidePanel] = useState(false);
        let style = {
          fontFamily: config.fonts,
        };
        if (config.backgroundChoice === "Color") {
          style.backgroundColor = config.colorHex;
        } else {
          style.backgroundImage = `url(${config.backgroundImage.file.url})`;
        }
        const reducingWarnings = () => {
          return;
        };
        return (
          <div
            role="contentinfo"
            className={
              defaultSiteNavigation
                ? headerStyle.header
                : headerStyle.alternativeHeader
            }
            style={style}
          >
            <div
              className={
                defaultSiteNavigation
                  ? headerStyle.maxWidthContainer
                  : headerStyle.alternativeMaxWidthContainer
              }
            >
              <div
                role="contentinfo"
                className={
                  defaultSiteNavigation
                    ? headerStyle.horizontalNav
                    : headerStyle.alternativeHorizontalNav
                }
              >
                <div role="contentinfo" className={headerStyle.logoWrapper}>
                  <Link className={headerStyle.nav__link} to="/">
                    <img
                      className={
                        defaultSiteNavigation
                          ? headerStyle.logo
                          : headerStyle.alternativeLogo
                      }
                      src={imgSrcPath}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <ul className={headerStyle.horizontalMenu}>
                  <li className={headerStyle.navWrapper}>
                    <div className={`${config.headerTheme}Header`}>
                      <a
                        className={headerStyle.nav__link}
                        onFocus={reducingWarnings}
                        onBlur={reducingWarnings}
                        href="/#home"
                      >
                        Home
                      </a>
                    </div>
                  </li>

                  <li className={headerStyle.navWrapper}>
                    <div className={`${config.headerTheme}Header`}>
                      <a
                        className={headerStyle.nav__link}
                        onFocus={reducingWarnings}
                        onBlur={reducingWarnings}
                        href="/#samplePacks"
                      >
                        Sample Packs
                      </a>
                    </div>
                  </li>

                  {defaultSiteNavigation ? (
                    <li className={headerStyle.navWrapper}>
                      <div className={`${config.headerTheme}Header`}>
                        <a
                          className={headerStyle.nav__link}
                          onFocus={reducingWarnings}
                          onBlur={reducingWarnings}
                          href="/#WhoWeAre"
                        >
                          Who We Are
                        </a>
                      </div>
                    </li>
                  ) : null}

                  <li className={headerStyle.navWrapper}>
                    <div className={`${config.headerTheme}Header`}>
                      <a
                        className={headerStyle.nav__link}
                        onFocus={reducingWarnings}
                        onBlur={reducingWarnings}
                        href="/#GetInTouch"
                      >
                        Get In Touch
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <div
                role="contentinfo"
                className={headerStyle.menuOpen}
                onClick={() => toggleSidePanel(!showSidePanel)}
                onKeyDown={reducingWarnings}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>

              <div
                className={`${headerStyle.sidePanel} ${
                  showSidePanel ? headerStyle.show : ""
                }`}
                onClick={() => toggleSidePanel(false)}
                onKeyDown={reducingWarnings}
              >
                <div className={headerStyle.menuClose}>
                  <span></span>
                  <span></span>
                </div>

                <ul className={headerStyle.verticalMenu}>
                  <li className={headerStyle.navWrapper}>
                    <a className={headerStyle.nav__link} href="/#home">
                      Home
                    </a>
                  </li>

                  <li className={headerStyle.navWrapper}>
                    <a className={headerStyle.nav__link} href="/#samplePacks">
                      Sample Packs
                    </a>
                  </li>
                  {defaultSiteNavigation ? (
                    <li className={headerStyle.navWrapper}>
                      <a className={headerStyle.nav__link} href="/#WhoWeAre">
                        Who We Are
                      </a>
                    </li>
                  ) : null}
                  <li className={headerStyle.navWrapper}>
                    <a className={headerStyle.nav__link} href="/#GetInTouch">
                      Get In Touch
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
